




































































































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {excuteDownloadExport2Excel} from "@/common/xlsx";
import {getPageAPIs, Util} from "@/common/util";
import {clearInterval} from "highcharts";
import GoodsSellAPIClass from "@/common/api/goodsSell";
export const pageApiList = getPageAPIs(GoodsSellAPIClass);
@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new GoodsSellAPIClass();
  private sellShow: boolean = false;
  private ischeck_goods: number = 1;
  private forms: any = {
    category_id: 0,
  };
  private msg: string = "";
  private input: number = 0;
  private phone: string = "";
  private userinfo: any = {
    type: 0,
  };
  private is_vip_pay: number = 0;

  private created() {
    if (this.$route.query.order_id) {
      this.goodsell();
    }
    if (this.$store.state.platformType === 2) {
      this.pay_type.shift()
    }
  }

  //会员查询
  private searchmember(): void {
    this.msg = "";
    this.buyAddressApi.searchmember(
        {
          mobile: this.phone,
        },
        (res: any) => {
          this.userinfo = res.data;
          if (res.data.nickname) {
            if (res.data.type != 0) {
              this.msg = `用户${res.data.nickname}会员余额：￥${res.data.money}`;
            } else {
              this.msg = "该用户还不是会员";
            }
          } else {
            this.msg = "未查询到此用户";
          }
        }
    );
  }

  private form: any = {
    name: '',
    order_no: '',
    time: [], // 时间选择
  };
  private pay: boolean = false;
  private pay_type: any = [
    {
      check: 4,
      name: "会员",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
    {
      check: 2,
      name: "微信或支付宝",
      icon: require("@/assets/dialog/icon／支付宝@2x.png"),
    },
    {
      check: 6,
      name: "现金",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
    {
      check: 3,
      name: "线下收款码",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
    // {
    //   check: 1,
    //   name: "微信",
    //   icon: require("@/assets/dialog/icon／微信@2x.png"),
    // },
  ];
  private check: number = 4;

  private changePayType(index: number): void {
    this.check = index;
    if (index == 1 || index == 2) {
      this.scanCodePay();
    }
  }

  private scanCode: boolean = false;

  private scanCodePay(): void {
    this.scanCode = true;
    this.getscanCode(2);
  }

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    const params = {
      name: this.form.name,
      order_no: this.form.order_no,
      start_date: this.form.time[0],
      end_date: this.form.time[1],
      page: this.tablePage.pageNo,
      limit: this.tablePage.pageSize,
    }
    this.buyAddressApi.goodsSalelists(
        {...params},
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  private category: any = [];

  private goodscategory(): void {
    this.buyAddressApi.goodscategory({}, (res: any) => {
      this.category = res.data.list;
    });
  }

  private goodsList: any = [];

  private goodslists(): void {
    this.buyAddressApi.goodsquery(
        {
          ...this.forms,
          page: 1,
          limit: 200,
        },
        (res: any) => {
          this.goodsList = res.data.list;
        }
    );
  }

  private selectgoodsList: any = [];

  private goodsell(): void {
    this.getscanCode(1);
    this.goodsprice = 0;
    this.restForms();
    this.ischeck_goods = 1;
    this.goodscategory();
    this.goodslists();
    this.selectgoodsList = [];
    this.selectgoods = new Set();
    this.sellShow = true;
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.getTable();
  }

  private restForms(): void {
    this.forms = new Util().clearObject(this.forms);
    this.forms.category_id = 0;
  }

  private selectgoods: any = new Set();

  private selectgood(row: any): void {
    console.log(87897987);
    row.num = 1;
    if (this.selectgoods.size > 0) {
      this.selectgoods.forEach((item: any) => {
        console.log(item);
        if (item.id != row.id) {
          this.selectgoods.add(row);
        }
      });
    } else {
      this.selectgoods.add(row);
    }

    // console.log(this.selectgoods.has(row))
    // if (this.selectgoods.has(row)) {
    //   console.log(this.selecgoodspricetgoods);
    // } else {
    //   this.selectgoods.add(row);
    //   console.log(this.selectgoods);
    // }
    this.selectgoodsList = [...this.selectgoods];
    this.getgoodsprice();
  }

  // private changenum(){

  // }
  private inputnum(): void {
    this.getgoodsprice();
  }

  private removegood(row: any): void {
    this.selectgoods.delete(row);
    this.selectgoodsList = [...this.selectgoods];
    this.getgoodsprice();
  }

  private setnum(type: number, row: any): void {
    if (type == 0) {
      if (row.num > 0) {
        row.num--;
      }
    } else {
      row.num++;
    }
    this.selectgoodsList = [...this.selectgoodsList];
    this.getgoodsprice();
  }

  private goodsprice: number = 0;

  private getgoodsprice(): void {
    this.goodsprice = 0;
    for (let i of this.selectgoodsList) {
      this.goodsprice += i.num * i.price;
    }
  }

  private goodcode: string = "";

  private getscanCode(type: number): void {
    let that = this;
    let str = "";
    document.onkeydown = async function (e: any) {
      console.log(str);
      if (e.target.tagName !== "BODY") {
        str = "";
      } else {
        if (e.keyCode !== 13) {
          if (e.keyCode >= 32 && e.keyCode <= 126) {
            let k = e.key;
            str += k;
          }
        } else {
          if (str) {
            console.log(str);
            if (type == 1) {
              that.goodcode = str;
              str = "";
              that.scanCodeadd();
            } else {
              if (that.auth_code != "") {
                console.log(7777);
                let res = await that.buygoods();
                console.log(res);
                if (res == 0) {
                  that.auth_code = str;
                  str = "";
                  that.payrequest();
                }
              } else {
                that.auth_code = str;
                str = "";
                that.payrequest();
              }
            }
          }
        }
      }
    };
  }

  private auth_code: string = "";
  private biz_type: number = 2;
  private timer: any = null;

  private payrequest(): void {
    this.buyAddressApi.payrequest(
        {
          order_id: this.orderinfo.id,
          pay_type: this.check,
          biz_type: this.biz_type,
          auth_code: this.auth_code,
          user_id: this.userinfo.id,
          // code: this.code,
          // is_vip_pay: this.is_vip_pay,
        },
        (res: any) => {
          if (this.buy_ok.includes(res.code)) {
            Util.showToast("支付成功");
            // this.renewal = false;
            // this.show_table = false;
            this.check = 4;
            this.code = "";
            (this.auth_code = ""), (this.pay = false);
            this.scanCode = false;
            this.getTable();
          } else if (this.buy_two.includes(res.code)) {
            if (res.code == 400217) {
              Util.showToast("需要用户输入支付密码");
              this.timer = window.setInterval(() => {
                this.payquery();
              }, 5000);
            } else if (res.code == 400218) {
              Util.showToast("付款码错误，请刷新二维码重试");
              this.timer = setInterval(() => {
                this.payquery();
              }, 5000);
            } else if (res.code == 400214) {
              Util.showToast("支付失败，余额不足");
              this.timer = setInterval(() => {
                this.payquery();
              }, 5000);
            }
          }
          // else {
          //   this.scanCode = false;
          //   // this.pay=false
          //   this.buygoods();
          // }
        },
        () => {
          if (this.timer == null) {
            this.timer = setInterval(() => {
              this.payquery();
            }, 5000);
          }
        }
    );
  }

  // private setbiz_type() {
  //   if (this.check == 1 || this.check == 2) {
  //     this.biz_type = 2;
  //   } else if (this.check == 4) {
  //     this.biz_type = 4;
  //   } else {
  //     this.biz_type = 3;
  //   }
  // }
  private code: string = "";

  private orderpay(): void {
    // //1商品预支付 2商品扫码收款
    if(this.check === 3  || this.check === 6){
      this.biz_type = 1
    }else{
      this.biz_type = 2
    }
    //现金支付或会员支付
    if (this.check === 3 || this.check === 4 || this.check === 6) {
      this.buyAddressApi.payrequest(
          {
            order_id: this.orderinfo.id,
            pay_type: this.check,
            biz_type: this.biz_type,
            user_id: this.userinfo.id,
            // code: this.code,
            // is_vip_pay: this.is_vip_pay,
          },
          () => {
            Util.showToast("支付成功");
            this.code = "";
            this.userinfo.id = "";
            this.msg = "";
            this.phone = "";
            this.pay = false;
            this.sellShow = false;
            this.scanCode = false;
            this.getTable();
          }
      );
      return
    }
    //微信或者支付宝
    this.getscanCode(2);
  }

  private scanCodeadd(): void {
    this.buyAddressApi.goodscan(
        {
          bar_code: this.goodcode,
        },
        (res: any) => {
          this.selectgood(res.data.item);
        }
    );
  }

  private orderinfo: any = {};

  private async buygoods() {
    let goods = "";
    for (let i of this.selectgoodsList) {
      goods += i.id + "=" + i.num + ",";
    }
    console.log(this.$route.query.order_id);
    if (this.$route.query.order_id) {
      this.buyAddressApi.goodsSalecart(
          {
            order_id: this.$route.query.order_id,
            cart_goods: goods,
          },
          () => {
            Util.showToast("添加成功");
            this.sellShow = false;
            this.$router.push("business");
          }
      );
    } else {
      return new Promise((resolve, reject) => {
        this.buyAddressApi.goodsSale({goods: goods}, (res: any) => {
          this.sellShow = false;
          this.orderinfo = res.data;
          this.pay = true;
          this.userinfo.id = "";
          this.msg = "";
          this.phone = "";
          resolve(0);
        });
      });
    }
    // console.log(resolve)
    // if (resolve != false) {
    //   return resolve();
    // }
  }

  private cancelpay(): void {
    this.buyAddressApi.cancelpay(
        {
          order_id: this.orderinfo.id,
        },
        () => {
          Util.showToast("取消成功");
          this.pay = false;
          this.scanCode = false;
          this.getTable();
        }
    );
  }

  private buy_ok = [200, 400220, 400213];
  private buy_two = [400217, 400218, 400214];
  private buy_no = [400211, 400212, 400215, 400216, 400219, 400221];

  private payquery(): void {
    this.buyAddressApi.payquery(
        {
          order_id: this.orderinfo.id,
          biz_type: 2,
        },
        (res: any) => {
          console.log(77777);
          if (this.buy_ok.includes(res.code)) {
            Util.showToast("支付成功");
            this.code = "";
            this.pay = false;
            this.scanCode = false;
            this.getTable();
            clearInterval(this.timer);
          }
        },
        (res: any) => {
          // if (this.buy_two.includes(res.code)) {
          //   if (res.code == 400217) {
          //     Util.showToast("需要用户输入支付密码");
          //   } else if (res.code == 400218) {
          //     Util.showToast("付款码错误，请刷新二维码重试");
          //   } else if (res.code == 400214) {
          //     Util.showToast("支付失败，余额不足");
          //   }
          // } else if (this.buy_no.includes(res.code)) {
          //   Util.showToast("支付失败");
          // }
        }
    );
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // channel渠道字段名 文案对应关系
  private merchantExcelList2ch: any = {
    order_no: "订单号",
    id: "序号",
    category_name: "分类名称",
    name: "商品名称",
    price: "单价",
    num: "数量",
    actual_price: "销售金额",
    pay_time: "售卖时间",
    operation_user: "操作人",
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    "order_no",
    "id",
    "category_name",
    "name",
    "price",
    "num",
    "actual_price",
    "pay_time",
    "operation_user",
  ];

  // 获取excelJSON数据
  private getExcelJSONDataByList(list: any[], sortKey: string[], map2ch: any) {
    const newList = list.map((eachItem) => {
      const newItem: any = {};

      for (const key of sortKey) {
        const val = eachItem[key];

        const chKey = map2ch[key];
        if (val) {
          newItem[chKey] = val;
        } else {
          newItem[chKey] = "";
        }
      }

      return newItem;
    });

    return newList;
  }

  // 点击导出excel 表格
  private handleExportExcelChannelList() {
    const form = this.form;
    const {time} = form;

    const params: any = form;

    if (Array.isArray(time) && time.length === 2) {
      // params.start_date = Util.dateTimeUtilnohms(this.form.time[0]);
      // params.end_date = Util.dateTimeUtilnohms(this.form.time[1]);
      params.start_date = this.form.time[0]
      params.end_date = this.form.time[1]
    } else {
      this.$message.error("请选择时段");

      return;
    }

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;

    let newParams = {
      ...this.form
    }
    delete newParams.time
    console.log('导出参数', newParams)
    this.buyAddressApi.goodsSalelists(
        {...newParams, is_export: 1},
        (res: any) => {
          const start_date: string = Util.dateUtil(this.form.time[0]);
          const end_date: string = Util.dateUtil(this.form.time[1]);
          // const start_date: string = this.form.time[0]
          // const end_date: string = this.form.time[1]
          const filename = `商品销售列表 ${start_date}到${end_date}.xlsx`;
          // const { list: originList } = res.data || {};
          const originList = res.data.lists || [];

          // const {
          //   list: originList
          // } = data || {}

          const newList = Array.isArray(originList)
              ? originList.map((item) => {
                // const { orderNum } = item || {};
                const newItem = {
                  ...(item || {}),
                };

                for (let key in item) {
                  if (typeof item[key] == "number") {
                    newItem[key] = item[key].toString();
                  }
                }

                // newItem.orderNum =
                //   typeof orderNum == "number"
                //     ? orderNum.toString()
                //     : orderNum || "";

                return newItem;
              })
              : [];

          if (newList.length > 0) {
            const merchantExcelSortList = this.merchantExcelSortList;

            const merchantExcelList2ch = this.merchantExcelList2ch;

            const excelJSONData = this.getExcelJSONDataByList(
                newList,
                merchantExcelSortList,
                merchantExcelList2ch
            );

            excuteDownloadExport2Excel(excelJSONData, filename);
          } else {
            this.$message.info("选中时段没有可导出的信息");
          }

          // const testData = [
          //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
          //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
          //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
          //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
          //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
          // ]

          this.downloadExcelLoading = false;
        },
        () => {
          this.downloadExcelLoading = false;
        }
    );
  }

  /** end 导出excel */
  @Watch("scanCode")
  private changescanCode(): void {
    if (!this.scanCode) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
  }
}
