import {BasicsClass} from "@/common/BasicsClass";

export default class GoodsSellAPIClass extends BasicsClass {
    public apiUrls: any = new Map([

        ['goodsSaleLists', {
            url: '/goodsSale/lists',
            name: 'goodsSaleLists',
            label: '商品售卖列表'
        }],
        ['goodsSaleBuy', {
            url: '/goodsSale/buy',
            name: 'goodsSaleBuy',
            label: '购买商品'
        }],
        ['goodsSaleCart', {
            url: '/goodsSale/cart',
            name: 'goodsSaleCart',
            label: '加入购物车'
        }],
        ['goodsQuery', {
            url: '/goods/query',
            name: 'goodsQuery',
            label: '手动查询销售商品'
        }],
        ['goodsCategory', {
            url: '/goods/category',
            name: 'goodsCategory',
            label: '商品分类'
        }],
        ['goodsScan', {
            url: '/goods/scan',
            name: 'goods-scan',
            label: '扫描条形码查询商品'
        }],
        ['orderCancel', {
            url: '/order/cancel',
            name: 'orderCancel',
            label: '取消订单'
        }],
        ['payQuery', {
            url: '/pay/query',
            name: 'payQuery',
            label: '查询支付订单'
        }],
        ['payRequest', {
            url: '/pay/request',
            name: 'pay-request',
            label: '支付请求'
        }],
        ['memberInfo', {
            url: '/member/info',
            name: 'memberInfo',
            label: '查询会员信息'
        }],

    ])

    //1
    public goodsSalelists(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleLists').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //2
    public goodsSale(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleBuy').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //3
    public goodsSalecart(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsSaleCart').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //4
    public goodsquery(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsQuery').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }


    //5
    public goodscategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsCategory').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //6
    public goodscan(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('goodsScan').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //7
    public cancelpay(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderCancel').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //8
    public payquery(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('payQuery').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //9
    public payrequest(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('payRequest').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //10
    public searchmember(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('memberInfo').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
